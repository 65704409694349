import RootStore from "../stores/root-store";
import React from "react";

export interface IBaseProps {
    rootStore?: RootStore
}

export default class Base extends React.Component<IBaseProps> {
    protected rootStore: RootStore | undefined;

    componentDidMount() {
        this.rootStore?.translationStore.applyTranslations();
    }

    constructor(props: IBaseProps) {
        super(props);
        this.rootStore = props.rootStore;
    }

    protected t(key: string, defaultValue: string): string {
        return this.rootStore?.translationStore.t(key, defaultValue) || defaultValue;
    }
}