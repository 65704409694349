import BaseStore from "./base-store";
import {ITranslation, LanguageEnum} from "../models/locale";
import {action, observable} from "mobx";
import RootStore from "./root-store";
import {LocalStorageEnum} from "../models/local-storage";
import enTranslations from "../locales/locale-en.json";

export default class TranslationStore extends BaseStore {
    @observable
    language: string = LanguageEnum.Et;

    @observable
    private translations: ITranslation[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.language = TranslationStore.initLanguage();
    }

    t(key: string, defaultValue: string): string {
        const translations = this.translations;
        const translation = translations.find(x => x.key === key)?.value;
        return translation || defaultValue;
    }

    changeLanguage(language: LanguageEnum) {
        this.setLanguage(language);
        this.applyTranslations(true);
    }

    applyTranslations(force: boolean = false) {
        if (this.language === LanguageEnum.En) {
            this.setTranslations(enTranslations as ITranslation[], force);
        } else {
            this.setTranslations([], force);
        }
    }

    private static initLanguage(): string {
        const language = localStorage.getItem(LocalStorageEnum.Language);
        return language || LanguageEnum.Et;
    }

    @action
    private setLanguage(language: LanguageEnum) {
        localStorage.setItem(LocalStorageEnum.Language, language);
        this.language = language;
    }

    @action
    private setTranslations(translations: ITranslation[], force: boolean) {
        if (this.translations.length === 0 || force) {
            this.translations = translations;
        }
    }
}