import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RootStore from "./stores/root-store";
import {Provider} from 'mobx-react';

const rootStore = new RootStore();

const stores = {
    rootStore: rootStore,
    translationStore: rootStore.translationStore
}

ReactDOM.render(
    <React.StrictMode>
        <Provider {...stores}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
