import * as React from "react";
import NavbarBrand from '../assets/images/NavbarBrand.png';
import Base from "./base";
import {inject, observer} from "mobx-react";
import {LanguageEnum} from "../models/locale";

@inject('rootStore')
@observer
export default class Navmenu extends Base {
    render() {
        const translationStore = this.props.rootStore?.translationStore;

        return <header className="section page-header">
            <div className="rd-navbar-wrap rd-navbar-wrap-transparent">
                <nav className="rd-navbar rd-navbar-trnsparent rd-navbar-classic" data-layout="rd-navbar-fixed"
                     data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
                     data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static"
                     data-lg-device-layout="rd-navbar-static" data-xl-layout="rd-navbar-static"
                     data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px"
                     data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="46px" data-lg-stick-up="true"
                     data-xl-stick-up="true" data-xxl-stick-up="true">
                    <div className="rd-navbar-main-outer">
                        <div className="rd-navbar-main">
                            <div className="rd-navbar-panel">
                                <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
                                    <span></span></button>
                                <div className="rd-navbar-brand">
                                    <a className="brand" href="/"><img
                                        className="brand-logo-dark" src={NavbarBrand}/>
                                        <span
                                            className="font-weight-bold text-third">{this.t("nav-menu.trrmain", "T.R.R Itaalia Konsultatsioonid")}</span></a>
                                </div>
                            </div>
                            <div className="rd-navbar-main-element">
                                <div className="rd-navbar-nav-wrap">
                                    <ul className="rd-navbar-nav">
                                        <li className="rd-nav-item active">
                                            <a className="rd-nav-link" href="#home">{this.t("nav-menu.home", "Avaleht")}</a>
                                        </li>
                                        <li className="rd-nav-item">
                                            <a className="rd-nav-link" href="#services">{this.t("nav-menu.services", "Teenused")}</a>
                                        </li>
                                        <li className="rd-nav-item"><a className="rd-nav-link"
                                                                       href="#about">{this.t("nav-menu.about","Minust")}</a>
                                        </li>
{/*                                        <li className="rd-nav-item"><a className="rd-nav-link"
                                                                       href="#realestate">{this.t("nav-menu.realestate","Kinnisvara")}</a>
                                        </li>*/}
                                        <li className="rd-nav-item"><a className="rd-nav-link"
                                                                       href="#blog">{this.t("nav-menu.blog","Blogi")}</a>
                                        </li>
                                        <li className="rd-nav-item"><a className="rd-nav-link"
                                                                       href="#contact">{this.t("nav-menu.contact","Kontakt")}</a>
                                        </li>
                                        <li className="rd-nav-item dropdown">
                                            <a className="rd-nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                               role="button" data-toggle="dropdown" aria-haspopup="true"
                                               aria-expanded="false">
                                                {this.t("nav-menu.language","Keel")}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="dropdown-item" href="#" onClick={() => translationStore?.changeLanguage(LanguageEnum.Et)}>Eesti keel</a>
                                                <a className="dropdown-item" href="#" onClick={() => translationStore?.changeLanguage(LanguageEnum.En)}>English</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>;
    }
}