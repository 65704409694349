import * as React from "react";
import BlogImg from '../assets/images/blogimg1.jpeg';
import Base from "./base";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export default class Realestate extends Base {
    render() {
        return <section className="section pt-10 bg-default">
            <div className="container">
                <div className="row-50">
                    <div className="text-center">
                        <article className="post-single pb-5">
                            <h3 className="post-single-title">Lahe tänav 16, Linn</h3>
                            <p>Kirjeldus kinnisvaraobjekti kohta.<br/><br/>
                                <img src={BlogImg} alt="" width="770" height="500"/></p>
                        </article>
                    </div>
                </div>
            </div>
        </section>;
    }
}