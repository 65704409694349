import React from 'react';
import Home from "./components/home";
import Navmenu from "./components/navmenu";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Realestate from "./components/realestate";
import Footer from "./components/footer";
import BlogPost1 from "./components/blog-post-1";

class App extends React.Component {
    render() {
        return <BrowserRouter>
            <Navmenu/>
            <div className="preloader">
                <div className="preloader-body">
                    <div className="cssload-container">
                        <div className="cssload-speeding-wheel"/>
                    </div>
                </div>
            </div>
            <div className="page">
                <Switch>
                    <Route path="/" exact={true} component={Home}/>
                    <Route path="/real-estate" component={Realestate}/>
                    <Route path="/blog-post-1" component={BlogPost1}/>
                </Switch>
            </div>
            <Footer/>
        </BrowserRouter>;
    }
}

export default App;
