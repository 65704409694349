import * as React from "react";
import BlogImg from '../assets/images/blogimg1.jpeg';
import HeaderImg from '../assets/images/Header_img.png';
import Base from "./base";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export default class BlogPost1 extends Base {
    render() {
        return <section className="section pt-10 bg-default">
            <div className="container">
                <div className="row row-50">
                    <div className="col-lg-9 col-xl-8">
                        <article className="post-single pb-5">
                            <h3 className="post-single-title">Kinnisvara ostmine Itaalias</h3>
                            <div className="post-single-meta">
                                <div className="badge">Õpetus</div>
                                <time className="post-single-time" dateTime="2019">09. märts, 2021</time>
                            </div>
                            <p>Ostuprotsess on Itaalias üsna lihtne, kuid, et kõik läheks nii sujuvalt nagu Te soovite,
                                peame käima läbi mõned sammud.<br/><br/>

                                <strong>Itaalia pangakonto ja maksukood (Codice Fiscale)</strong><br/><br/>

                                Itaalias kinnisvara ostmiseks peab teil olema Itaalia pangakonto ja Itaalia maksukood
                                (Codice Fiscale). Itaalia pangakonto taotlemiseks peate olema siin isiklikult kohal ja
                                maksukoodi saab taotleda kas siin Itaalias või teie asukohariigi saatkonnas /
                                konsulaadis. Maja otsimise protsessi alguses soovitan teil korraldada nii pangakonto kui
                                ka maksukood koheselt, kuna see võtab aega. TRR-il on pankadega tihe koostöö ja aitan
                                teid hea meelega pangakonto avamise protsessis ja maksukoodi taotlemisel.<br/><br/>

                                <strong>Majajaht Itaalias</strong><br/><br/>

                                Vastavalt teie spetsifikatsioonidele, soovidele ja eelarvele TRR aitab Teil otsida maju
                                teile väljavaatamiseks. Vale otsuse tegemise vältimiseks nõustan Teid ka kinnisvara
                                piirkondade, asukohtade jms osas.
                                <img src={BlogImg} alt="" width="770" height="500"/>
                                <br/><br/><strong>Uuringud</strong><br/><br/>

                                Itaalias ei ole kinnisvara ülevaatuse tegemine kohustuslik, aga võimaliku uuringu eest
                                maksab alati ostja. Kui soovite korraldada kinnisvarauuringu, võtan hea meelega ühendust
                                geomeetriaga, kes hindab kinnisvara teie eest. Kui plaanite teha mingeid ehitus- või
                                renoveerimistöid, on geomeetria ka kogu projekti jaoks nõutud palgatud professionaal.
                                Itaalias ostate kinnisvara alati "sellisena nagu see on" ja seetõttu on enne esimese
                                lepingu sõlmimist väga oluline kinnisvara kõik kontrollid läbi viia ja hind vastavalt
                                läbi rääkida (kompromissleping). Suurt rolli mängib Itaalia kultuuris maja hinna
                                kauplemine.

                                <br/><br/><strong>Esimene leping - Compromesso/contratto preliminare</strong><br/><br/>

                                Kui ostu hind ja tingimused on müüjaga kokku lepitud, on aeg sõlmida esimene leping
                                (Compromesso). See on leping, mis sätestab müügihinna, millal lõplik leping sõlmitakse
                                jne. Kui leping on sõlmitud, seob Compromesso nii teid kui ka müüjat müügiga. Selles
                                etapis peaksite maksma tagatisraha (tavaliselt 10-15% vara müügihinnast) ja maakleri
                                vahendustasu umbes 3%. Kui loobute pärast kompromisskirja allkirjastamist, kaotate oma
                                deposiidi ja vahendustasu.

                                <br/><br/><strong>Lõplik leping notaris</strong><br/><br/>

                                Lõpliku akti koostab notar (Notaio). Itaalia seaduste kohaselt peab müügikiri toimuma
                                notari juures. Notar kontrollib enne lepingu allkirjastamist ka seda, et maja on
                                hüpoteekidest vaba, et tasutud maks on tasutud, et maja omavad seda müüvad inimesed jne.
                                Lõplik rahaülekanne müüjale toimub notaris. Arvutatakse notarile tasu tema teenuste eest
                                arvestades maja ostuhinda. Need maksed tehakse tavaliselt tšekiga Assegno Circolare
                                (teie panga välja antud ja garanteeritud), või notari deposiidiga. Kui te ei räägi
                                soravalt itaalia keelt, on vajalik, et volitatud tõlk koostaks lepingu tõlke ja oleks
                                kohal ka tõlkimisel lepingu allkirjastamise ajal notaris.

                                <br/><br/><strong>Itaalias kinnisvara ostmise kulud</strong><br/><br/>

                                Suunisena peate arvestama 10% kinnisvara müügihinnast Itaalia ostukulude katteks.
                                Ostumaks varieerub sõltuvalt sellest, kas elate Itaalias (Prima Casa) või kavatsete osta
                                teise majana (Seconda Casa) ja see mõjutab muidugi lõplikke ostukulusid. Kui ostate
                                kinnistu Prima Casa nime all, maksate ostumaksu 2% vara maksustatavast väärtusest ja kui
                                ostate Seconda Casana, maksate 9%. See erineb juhul, kui ostate uue hoone, mis tähendab,
                                et maksate 4% käibemaksu Prima Casa ja 10% käibemaksu Seconda Casa.

                                <br/><br/><strong>Peale kinnisvara ostu:</strong><br/><br/>

                                Peale kinnisvara ostu on tarvis kõik kommunikatsioonide, elektri, vee gaasi jne lepingud
                                teha uue omaniku nimele. Prima Casana ostes kinnisvara ja mitte olles Itaalia resident,
                                on kohustus uuel omanikul vormistada ennast taalia residendiks vähemalt 18 kuu jooksul
                                peale maja ostu. Tuleb linnavalitsuses käia, registreerida ennast uue omanikuna, ning
                                vormistada oma nimele prügileping.

                                <br/><br/><strong>Ostukulud hõlmavad järgmist:</strong><br/><br/>

                                Ostumaks<br/>
                                Käibemaks uusehitise korral<br/>
                                Maakleri komisjon<br/>
                                Notari tasu<br/>
                                Registreerimistasu<br/>
                                Tõlketasu</p>
                        </article>
                    </div>
                    <div className="col-lg-3 col-xl-4">
                        <article className="box-info">
                            <h3 className="box-info-title">Autor</h3>
                            <div className="box-info-inner"><img className="box-info-avatar" src={HeaderImg}
                                                                 alt="" width="180" height="180"/>
                                <div className="box-info-main">
                                    <h5 className="box-info-caption">Tony-Reinhold Raadik</h5>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>;
    }
}