import * as React from "react";
import BackgroundImage from '../assets/images/main-section-bg-1440x800.jpg';
import HeaderImg from '../assets/images/Header_img.png';
import Signature from '../assets/images/sig.png';
import About from '../assets/images/aboutImg.png';
import BlogImg from '../assets/images/blogimg1.jpeg';
import Feedback1 from '../assets/images/feedback1.png';
import Feedback2 from '../assets/images/feedback2.jpg';
import Base from "./base";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export default class Home extends Base {
    render() {
        return <div>
            <section className="section section-header main-section bg-image"
                     style={{backgroundImage: `url(${BackgroundImage})`}} id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-top-150 wow fadeIn" data-wow-delay=".3s">
                            <h1 className="font-weight-sbold text-center text-lg-left">{this.t("home.slogan", "Professionaalne konsultatsiooniteenus Eesti-Itaalia suunal")}<br className="d-none d-lg-block"/></h1>
                            <h6 className="font-weight-light text-center text-lg-left">{this.t("home.landingtext", "Pakume klientidele mitmeid võimalusi Itaalias elamist ja äri korraldada.")} <br
                                    className="d-none d-lg-block"/>{this.t("home.ask", "Küsige")}<span
                                    className="text-primary font-weight-bold">{this.t("home.free", " tasuta")}</span> {this.t("home.consultancy", "konsultatsiooni juba täna!")}</h6>
                            <div>
                                <h5 className="pt-4 text-center text-lg-left">"{this.t("home.moto", "Pole probleeme, on ainult lahendused!")}"</h5>
                                <img className="pt-3 img-signature text-lg-left"
                                     src={Signature}/>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-wow-delay=".5s">
                            <div className="main-section-img text-center pt-4"><img src={HeaderImg}
                                                                                    alt="" width="519"
                                                                                    height="912"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-lg section-lg-2 bg-gray-200 bg-overlay-100" id="services">
                <div className="circle-bg">
                    <div className="container">
                        <h2 className="font-weight-sbold text-center wow fadeIn" data-wow-delay=".3s">{this.t("nav-menu.services", "Teenused")}</h2>
                        <h6 className="font-weight-light text-center box-1 text-gray-670 wow fadeIn"
                            data-wow-delay=".4s">{this.t("services.about", "T.R.R pakub Itaalia keele tõlketeenust, projektijuhtimist ning konsultatsiooni Itaalia suunal järgmistes valdkondades:")}</h6>
                        <div className="box-services bg-default wow fadeInUp" data-wow-delay=".2s">
                            <div className="row row-50">
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-home"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.realestate", "Kinnisvaraga seotud tehingud")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.buying", "Kinnisvara soetamine Itaalias")}<br/>
                                                2) {this.t("services.notar", "Suhtlemine notaritega, et maja ostu protsess sujuks kiirelt ja kindlalt")}<br/>
                                                3) {this.t("services.agent", "Suhtlemine maakleriga")}<br/>
                                                4) {this.t("services.permits", "Ehitusload / Projektid / UNESCO")}<br/>
                                                5) {this.t("services.backgroundcheck", "Kinnisvara ostmise taustakontroll")}<br/>
                                                6) {this.t("services.architect", "Arhitekti teenuse vahendamine")}<br/>
                                                7) {this.t("services.certificates", "Sertifikaadid")}</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-pencil"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.translation", "Tõlketeenus Itaalia-Eesti-Inglise")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.mails", "Tekstide ja meilide tõlkimine ")} <br/>
                                                2) {this.t("services.directspot", "Otsetõlge kohapeal Itaalias ")}<br/>
                                                3) {this.t("services.directphone", "Otsetõlge telefoni teel ")} <br/>
                                                4) {this.t("services.apostolic", "Apostilliga tekstid ja notariaalsed lepingud")}</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-address-book"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.life", "Elukorralduslikud küsimused")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.residency", "Residentsus")}<br/>
                                                2) {this.t("services.bank", "Pangakonto")}<br/>
                                                3) {this.t("services.health", "Tervisekindlustus")}<br/>
                                                4) {this.t("services.rental", "Elamispinna rentimine")} <br/>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-bank"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.business", "Elamispinna rentimine")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.company", "Ettevõtte asutamine")}<br/>
                                                2) {this.t("services.businessactivity", "Äritegevus")}<br/>
                                                3) {this.t("services.taxation", "Maksundus")}<br/>
                                                4) {this.t("services.accounting", "Raamatupidamine")}<br/>
                                                5) {this.t("services.pm", "Projektijuhtimine")}<br/>
                                                6) {this.t("services.construction", "Ehitustehnika rentimine")}<br/>
                                                7) {this.t("services.impexp", "Import / Export")}</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-lightbulb-o"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.other", "Muud teenused")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.organization", "Igasuguste tähtpäevade korraldamine")}<br/>
                                                2) {this.t("services.conferences", "Konverentsid / seminarid")}<br/>
                                                3) {this.t("services.travel", "Reisikorraldus / soovitused")}<br/>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <article className="box-icon-classic">
                                        <div className="unit-left"><span className="fa fa-black-tie"/>
                                        </div>
                                        <div className="unit-body">
                                            <h5 className="box-icon-classic-title"><a href="single-service.html">{this.t("services.court", "Itaalia kohtusüsteem")}</a></h5>
                                            <p className="box-icon-classic-text">
                                                1) {this.t("services.advocate", "Advokatuur")}<br/>
                                                2) {this.t("services.communication", "Suhtlus / vahendamine")}<br/>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h6 className="pt-5 pb-5 font-weight-light text-center text-gray-670 wow fadeIn"
                                data-wow-delay=".4s">{this.t("services.slogan1", "Kui oled valmis saavutama väärilist edu ja rahulolu või uue elu alustust Itaalias sujuvalt ja kindlalt, \n" +
                                "siis küsi tasuta konsultatsiooni juba täna!")}<br/>{this.t("services.firstconsult", "Esimesed 1h konsultatsiooni on tasuta! ")}</h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl section-xl-2">
                <div className="container">
                    <h2 className="font-weight-sbold text-center wow fadeIn" data-wow-delay=".2s">{this.t("pricing.header", "Hinnakiri")}</h2>
                    <h6 className="pt-5 font-weight-light text-center text-third wow fadeIn"
                        data-wow-delay=".3s">{this.t("pricing.custompricing", "T.R.R Itaalia konsultatsioon teeb igale kliendile hinnapakkumise olenevalt küsimusest ja töö keerukusest.")}</h6>
                    <div className="row row-40 offset-top-0 offset-top-48">
                        <div className="col-md-12 col-lg-12 wow fadeInLeft" data-wow-delay=".2s">
{/*                            <h6 className="font-weight-light text-center">{this.t("pricing.main", "Põhihinnakiri")} <strong>90 EUR
                                {this.t("pricing.hour", " tund")}. </strong>{this.t("pricing.phone", "Telefonikõned, kirjavahetus, vahendamine ja tõlkimine.")}</h6>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-lg bg-gray-100" id="about">
                <div className="container">
                    <div className="row row-40">
                        <div className="col-lg-6 text-center wow fadeInLeft" data-wow-delay=".2s"><img
                            className="about-img" src={About}/>
                        </div>
                        <div className="col-lg-6 text-center text-lg-left wow fadeInRight block-lg-2"
                             data-wow-delay=".2s">
                            <h2 className="font-weight-sbold">Tony-Reinhold Raadik</h2>
                            <div className="row row-40 offset-top-0 offset-top-48-2">
                                <div className="col-6">
                                    <span className="fa fa-italic"/>
                                    <h5 className="font-weight-sbold ls-1 offset-top-10">{this.t("about.italy", "5 aastat edukat Itaalia elu")}</h5>
                                </div>
                                <div className="col-6">
                                    <span className="fa fa-book"/>
                                    <h5 className="font-weight-sbold ls-1 offset-top-10">{this.t("about.xp", "Põnevad kogemused")}</h5>
                                </div>
                                <div className="col-6">
                                    <span className="fa fa-connectdevelop"/>
                                    <h5 className="font-weight-sbold ls-1 offset-top-10">{this.t("about.projects", "Edukad projektid")}</h5>
                                </div>
                                <div className="col-6">
                                    <span className="fa fa-graduation-cap"/>
                                    <h5 className="font-weight-sbold ls-1 offset-top-10">{this.t("about.culture", "Itaalia kultuurimaastik")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <h2 className="font-weight-sbold text-center">{this.t("nav-menu.about", "Minust")}</h2>
                    <div className="row row-40">
                        <div className="col-sm-12 col-md-6-col-lg-6 text-center offset-top-10 wow fadeInLeft" data-wow-delay=".2s">
                            <div className="p-5">
                                <h4 className="font-weight-light text-justify">
                                    <strong>Tony-Reinhold Raadik</strong> {this.t("about.tony", " saabus Itaalia pinnale aastal 2015, \n" +
                                    "millele on järgnenud tormilised 5 aastat käies läbi erinevad Itaalia kultuurimaastikul põnevad äriprotsessid ning ehitamised. ")} <br/>
                                </h4>
                                <h4 className="font-weight-light">{this.t("about.tony2", "Kogemused on tulnud isiklikul kokkupuutel ostes kinnisvara, luues kontakte, suheldes arhitektidega, notaritega ja ehitajatega. Tehes läbi protsessid kõik mis on seotud ehitusega ja selle seadustamisega. Ettevõtte loomine ja juhtimine.")}<br/>
                                </h4>
                                <h4 className="font-weight-light">{this.t("about.5years", "5 aastane kogemus; visioonid; strateegiad; projektijuhtimine; turundus; ehitusload; juhtimine")}
                                </h4>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6-col-lg-6 text-center offset-top-10 wow fadeInLeft" data-wow-delay=".2s">
                            <div className="p-5">
                                <h4 className="font-weight-light text-justify"><strong>{this.t("about.personal", "Isikuomadused:")}</strong><br/>
                                    {this.t("about.accuracy", "Täpsus, tahtejõud, juhtimine, konkreetsus")}, {this.t("about.attitude", "Suhtumine")} "{this.t("about.somehow", "Kui kuidagi ei saa, siis kuidagi ikka saab")}"</h4>
                                <h4 className="font-weight-light text-justify"><strong>Moto</strong><br/>
                                    "{this.t("home.moto", "Pole probleeme, on ainult lahendused")}"</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

{/*            <section className="section section-xl bg-default" id="realestate">
                <div className="container">
                    <h2 className="font-weight-sbold text-center wow fadeIn" data-wow-delay=".2s">{this.t("nav-menu.realestate", "Kinnisvara")}</h2>
                    <div className="row row-50 offset-top-0 offset-top-43">
                        <div className="col-sm-6 col-lg-4 text-center text-sm-left wow fadeInRight"
                             data-wow-delay=".2s">
                            <a className="overlay-img" href="/real-estate">
                                <img src={BlogImg} alt="" width="370" height="260"/></a>
                            <h5 className="font-weight-sbold ls-1 pt-3">Korter 1</h5>
                        </div>
                    </div>
                </div>
            </section>*/}

            <section className="section section-lg bg-gray-100">
                <div className="container">
                    <h2 className="font-weight-sbold text-center wow fadeIn" data-wow-delay=".2s">{this.t("feedback.header", "Tagasiside klientidelt")}</h2>
                    <div className="owl-carousel owl-carousel-custom-2 wow fadeIn" data-items="1" data-sm-items="1"
                         data-md-items="1" data-lg-items="1" data-xl-items="1" data-xxl-items="1" data-margin="0"
                         data-nav="true" data-dots="true" data-autoplay="7000" style={{marginTop: "46px"}}
                         data-wow-delay=".4s" data-animation-in="fadeIn">
                        <div className="item">
                            <div className="item-block-img"><img className="img-radius-1" src={Feedback1}
                                                                 alt="" width="80" height="80"/>
                            </div>
                            <div className="item-block-text">
                                <h4 className="font-weight-light text-gray-770 text-center box-2">{this.t("feedback.viljandi", "Koostöö on sujunud väga positiivses võtmes. Tony on protsessis osavõtlik ja meeldiv suhtleja")}.</h4>
                                <h5 className="font-weight-sbold ls-1 text-center">Viljandi Metall Group</h5>
                                <div className="small-text font-weight-light text-center">{this.t("feedback.client", "Klient")}</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-block-img"><img className="img-radius-1" src={Feedback2}
                                                                 alt="" width="80" height="80"/>
                            </div>
                            <div className="item-block-text">
                                <h4 className="font-weight-light text-gray-770 text-center box-2">{this.t("feedback.rein", "Täpne, korrektne ja kiire kõikide küsimuste lahendamisel. Äärmiselt sõbralik ja suurepärane koostööpartner")}.</h4>
                                <h5 className="font-weight-sbold ls-1 text-center">Rein Kärk</h5>
                                <div className="small-text font-weight-light text-center">{this.t("feedback.client", "Klient")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default" id="blog">
                <div className="container">
                    <h2 className="font-weight-sbold text-center wow fadeIn" data-wow-delay=".2s">{this.t("blog.header", "Blogi")}</h2>
                    <div className="row row-50 offset-top-0 offset-top-43">
                        <div className="col-sm-6 col-lg-4 text-center text-sm-left wow fadeInRight"
                             data-wow-delay=".2s"><a className="overlay-img" href="/blog-post-1"><img
                            src={BlogImg} alt="" width="370" height="260"/></a>
                            <div className="small-text font-weight-light text-gray-650 offset-top-30">{this.t("blog.date", "09. märts, 2021")}
                            </div>
                            <h5 className="font-weight-sbold ls-1"><a href="/blog-post-1">Kinnisvara ostmine
                                Itaalias</a></h5><a
                                className="button button-icon button-icon-right text-primary button-lg"
                                href="/blog-post-1"><span>{this.t("blog.btn", "Loe rohkem")}</span><span className="arrow">
                  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.29111 0.146484C7.48168 -0.048828 7.79084 -0.048828 7.98142 0.146484L10.8571 3.09173C10.9196 3.15582 10.9617 3.23296 10.9832 3.31457C11.0026 3.38842 11.0052 3.466 10.991 3.54082C10.9736 3.63671 10.9295 3.72845 10.8571 3.8026L7.98142 6.85356C7.79084 7.04881 7.48168 7.04881 7.29111 6.85356C7.10042 6.65825 7.10042 6.34172 7.29111 6.14641L9.42663 3.85357H0.488175C0.218583 3.85357 1.7879e-06 3.62969 1.7879e-06 3.35357C1.7879e-06 3.07745 0.218583 2.85357 0.488175 2.85357H9.24368L7.29111 0.853575C7.10042 0.658324 7.10042 0.341735 7.29111 0.146484Z"
                        fill="#4173F2"/>
                  </svg></span></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-default" id="contact">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-lg-12 section-lg first-section">
                            <h2 className="font-weight-sbold wow fadeIn" data-wow-delay=".2s">{this.t("nav-menu.contact", "Kontakt")}</h2>
                            <h5 className="font-weight-sbold ls-1 wow fadeInLeft" data-wow-delay=".2s">{this.t("contact.address", "Aadress")}</h5>
                            <h6 className="font-weight-light text-gray-650 wow fadeInLeft" data-wow-delay=".2s">Vene
                                20, 10123 Tallinn</h6>
                            <h5 className="font-weight-sbold ls-1 offset-top-36 wow fadeInLeft"
                                data-wow-delay=".3s">{this.t("contact.phone", "Telefon")}</h5>
                            <h6 className="font-weight-light text-gray-650 wow fadeInLeft" data-wow-delay=".3s"><a
                                href="tel:+3725570764">+372 557 0764</a></h6>
                            <h5 className="font-weight-sbold ls-1 offset-top-36 wow fadeInLeft"
                                data-wow-delay=".4s">E-mail</h5>
                            <h6 className="font-weight-light text-gray-650 wow fadeInLeft" data-wow-delay=".4s"><a
                                href="mailto:info@itaaliakonsultatsioonid.ee">info@itaaliakonsultatsioonid.ee</a>
                            </h6>
                            <h5 className="font-weight-sbold ls-1 offset-top-36 wow fadeInLeft"
                                data-wow-delay=".4s">{this.t("contact.languages", "Suhtluskeeled")}</h5>
                            <h6 className="font-weight-light text-gray-650 wow fadeInLeft" data-wow-delay=".4s"><a>{this.t("contact.languagesdetail", "Eesti, inglise, itaalia, soome")}</a></h6>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-12 section-lg first-section text-center">
                            <h2 className="font-weight-sbold wow fadeIn" data-wow-delay=".2s">{this.t("consult.header", "Konsultatsioon")}</h2>
                            <h6 className="font-weight-light wow fadeIn" data-wow-delay=".3s">{this.t("consult.register", "Registreerige kohe tasuta konsultatsioonile")}!</h6>
                            <form className="text-center pt-5 rd-form rd-mailform form-2" data-form-output="form-output-global" data-form-type="contact" method="post" action="php/bat/rd-mailform.php">
                                <div className="form-wrap form-wrap-2">
                                    <input className="form-input" id="contact-name" type="text" name="name"
                                           data-constraints="@Required"/>
                                    <label className="form-label" htmlFor="contact-name">{this.t("form.name", "Sinu nimi")}</label>
                                </div>
                                <div className="form-wrap form-wrap-2">
                                    <input className="form-input" id="contact-2-phone" type="text" name="phone"
                                           data-constraints="@Numeric"/>
                                    <label className="form-label" htmlFor="contact-2-phone">{this.t("form.number", "Sinu telefon")}</label>
                                </div>
                                <div className="form-wrap form-wrap-2">
                                    <label className="form-label" htmlFor="contact-message">{this.t("form.message", "Sõnum")}</label>
                                    <textarea className="form-input" id="contact-message" name="message"
                                              data-constraints="@Required"/>
                                </div>
                                <button className="button button-block button-primary" type="submit">{this.t("form.send", "Saada")}</button>
                            </form>
                            <div className="snackbars" id="form-output-global"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}