import * as mobx from "mobx";
import TranslationStore from "./translation-store";

interface IRootStore {
    translationStore: TranslationStore
}

export default class RootStore implements IRootStore {
    translationStore: TranslationStore;

    constructor() {
        mobx.configure({
            enforceActions: "observed"
        });

        this.translationStore = new TranslationStore(this);
    }
}