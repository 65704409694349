import * as React from "react";
import NavbarBrand from '../assets/images/NavbarBrand.png';
import Base from "./base";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export default class Footer extends Base {
    render() {
        return <footer className="section footer-classic">
            <div className="container">
                <div className="row row-40">
                    <div className="col-sm-6 col-lg-4 text-center text-sm-left wow fadeInUp"
                         data-wow-delay=".04s"><a href="/"><img src={NavbarBrand} alt=""
                                                                         width="80" height="20"/></a>
                        <p className="font-weight-light text-gray-620 lh-1 box-3 offset-top-30"><span
                            className="font-weight-bold text-third">{this.t("footer.consultancy", "T.R.R Itaalia Konsultatsioonid")}</span>{this.t("footer.consultancyabout", " pakub professionaalset konsultatsiooni teenust Eesti-Itaalia suunal")}</p>
                    </div>
                    <div className="col-sm-6 col-lg-4 block-lg-4 text-center text-sm-left wow fadeInUp"
                         data-wow-delay=".04s">
                        <h6 className="font-weight-regular text-third">{this.t("footer.navigation", "Viited")}</h6>
                        <ul className="offset-top-25">
                            <li><a className="font-weight-light text-gray-620 lh-2"
                                   href="/">{this.t("nav-menu.home", "Avaleht")}</a></li>
                            <li><a className="font-weight-light text-gray-620 lh-2"
                                   href="#services">{this.t("nav-menu.services", "Teenused")}</a></li>
                            <li><a className="font-weight-light text-gray-620 lh-2" href="#about">{this.t("nav-menu.about","Minust")}</a>
                            </li>
{/*                            <li><a className="font-weight-light text-gray-620 lh-2"
                                   href="#realestate">Kinnisvara</a></li>*/}
                            <li><a className="font-weight-light text-gray-620 lh-2" href="#blog">{this.t("nav-menu.blog","Blogi")}</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-4 text-center text-sm-left wow fadeInUp"
                         data-wow-delay=".04s">
                        <h6 className="font-weight-regular text-third">{this.t("footer.company","Ettevõttest")}</h6>
                        <ul className="offset-top-25">
                            <li><a className="font-weight-light text-gray-620 lh-2">Monoro OÜ</a></li>
                            <li><a className="font-weight-light text-gray-620 lh-2">{this.t("footer.code","Reg. kood")}: 14997267</a></li>
                            <li><a className="font-weight-light text-gray-620 lh-2">Vene 20, 10123 Tallinn</a></li>
                            <li><a className="font-weight-light text-gray-620 lh-2" href="https://www.facebook.com/TRRKonsultatsioon/"><span className="fa-facebook"/></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-classic-bottom">
                    <p className="rights"><span>&copy;&nbsp; </span><span
                        className="copyright-year"/><span>&nbsp;</span><span>{this.t("footer.consultancy", "T.R.R Itaalia Konsultatsioonid")}</span><span>.&nbsp;</span><span>{this.t("footer.copy", "Kõik õigused reserveeritud")}.</span>
                    </p>
{/*                    <p className="rights-2"><a href="privacy-policy.html">Privaatsuspoliitika</a></p>*/}
                </div>
            </div>
        </footer>;
    }
}